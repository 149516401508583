































































import { Component, Vue, Prop } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import CompaniesModule from "@/store/clients/Companies.module";
import VesselsAdminModule from "@/store/clients/VesselsAdmin.module";
import UsersModule from "@/store/clients/Users.module";
import VesselAccessGroupsModule from "@/store/clients/VesselAccessGroups.module";

const Companies = getModule(CompaniesModule, store);
const VesselsAdmin = getModule(VesselsAdminModule, store);
const Users = getModule(UsersModule, store);
const VesselAccessGroups = getModule(VesselAccessGroupsModule, store);

@Component
export default class InfoCardList extends Vue {
  @Prop({ default: false }) isAdmin!: boolean;
  // Companies getters
  get companiesCount(): number {
    return Companies.companiesCount;
  }

  // VesselsAdmin getters
  get UnassignedVesselsCount(): number {
    return VesselsAdmin.UnassignedVesselsCount;
  }
  get ActiveVesselsCount(): number {
    return VesselsAdmin.ActiveVesselsCount;
  }
  get InactiveVesselsCount(): number {
    return VesselsAdmin.InactiveVesselsCount;
  }

  // Users getters
  get usersCount(): number {
    return Users.usersCount;
  }

  // VesselCategories getters
  get vesselAccessGroupsCount(): number {
    return VesselAccessGroups.vesselAccessGroupsCount;
  }
}
