
















































































































import { Component, PropSync, Vue } from "vue-property-decorator";
import Utilities from "@/utilities/utilities";
import { FormattedError } from "@/types/formattedError";
import { ChangePasswordData } from "@/types/changePasswordData";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import AuthModule from "@/store/clients/Auth.module";
import UserModule from "@/store/clients/User.module";

const User = getModule(UserModule, store);
const Auth = getModule(AuthModule, store);

@Component
export default class ChangePassword extends Vue {
  //Two way prop to handle open/close popup both from parent and component
  @PropSync("openPopup", { type: Boolean, default: false }) ChangePasswordPopup!: boolean;
  //data to send
  payload: ChangePasswordData = { username: "", currentPassword: "", newPassword: "" };
  //change this if we want to alter password minimum length frontend. Currently it is 6 on the server
  minimumPasswordLength = 6;
  //form state
  isFormValid = true;
  //toggle input type to reveal password
  showPassword = false;
  showNewPassword = false;
  //holds the confirm password string
  repeatPassword = "";
  //state submitted
  submittedError = false;
  //state submitting
  submitting = false;
  //Errormessage is displayed in a v-alert
  errorResponse!: FormattedError;
  showChangedPasswordNotification = false;
  //How long the notification is shown on screen in ms
  notificationDurationMs = 3500;
  //input validation
  passwordRules = {
    required: (value: string) => !!value || "Password required",
    valid: (value: string) => (value && value.length >= this.minimumPasswordLength) || `Password must be at least ${this.minimumPasswordLength} characters`,
  };

  //validate if passwords are equal
  get passwordsEqual(): boolean | string {
    return this.passwordsEqualBool || "Password mismatch";
  }

  get passwordsEqualBool(): boolean {
    return this.repeatPassword === this.payload.newPassword;
  }

  created(): void {
    this.payload.username = User.userEmail;
  }

  handleChangePassword(): void {
    this.submittedError = false;
    this.submitting = true;
    const form: any = this.$refs.changePassword;
    Auth.changePassword(this.payload).then(
      response => {
        form.reset();
        this.submitting = false;
        this.ChangePasswordPopup = false;
        this.showPasswordUpdatedNotification();
      },
      error => {
        form.reset();
        this.errorResponse = Utilities.formatError(error);
        this.submittedError = true;
        this.submitting = false;
      }
    );
  }

  showPasswordUpdatedNotification(): void {
    this.showChangedPasswordNotification = true;
  }
}
