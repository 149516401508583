










































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
//  store modules
import UserModule from "@/store/clients/User.module";
import UsersModule from "@/store/clients/Users.module";
import CompaniesModule from "@/store/clients/Companies.module";

//  components
import ChangePassword from "@/components/ChangePassword.vue";
import EditProfile from "@/components/EditProfile.vue";
import ApiKey from "@/components/ApiKey.vue";

import { Company } from "@/types/company";

const User = getModule(UserModule, store);
const Users = getModule(UsersModule, store);
const Companies = getModule(CompaniesModule, store);

@Component({
  components: {
    ChangePassword,
    EditProfile,
    ApiKey,
  },
})
export default class UserData extends Vue {
  activeCompanyId: number | null = null;
  ChangePasswordPopup = false;
  EditProfilePopup = false;
  ApiKeyPopup = false;

  @Watch("activeCompanyId")
  onCompanyChanged(val: number, oldVal: number | undefined): void {
    if (oldVal === null) return;
    if (val !== oldVal) {
      Users.viewAsCompany(val).then(() => {
        window.location.reload();
      });
    }
  }

  // User getters
  get userName(): string {
    return User.userName;
  }
  get userTitle(): string {
    return User.userTitle;
  }
  get userEmail(): string {
    return User.userEmail;
  }
  get userPhone(): string | null {
    return User.userPhone;
  }
  get isAdmin(): boolean {
    return User.isAdmin;
  }
  get isSuperOrAdmin(): boolean {
    return User.isSuperOrAdmin;
  }
  get userCompanyId(): number | null {
    return User.userCompanyId;
  }

  // Companies getters
  get companies(): Company[] {
    return Companies.companies;
  }

  created(): void {
    this.activeCompanyId = this.userCompanyId;
  }
}
