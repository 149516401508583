





























































































import { Component, PropSync, Ref, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/clients/User.module";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

const User = getModule(UserModule, store);

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class ApiKey extends Vue {
  //Two way prop to handle open/close popup both from parent and component
  @PropSync("openPopup", { type: Boolean, default: false }) open!: boolean;
  @Ref("confirmDelete") confirmDelete!: any;

  currentApiKey = "";
  submittedError = false;
  submitting = false;
  notificationDurationMs = 3500;
  hasActiveApiKey = true;
  generateApiKeyDialogOpen = false;
  notification = {
    message: "",
    duration: 2000,
    visible: false,
  };

  created(): void {
    this.currentApiKey = User.userApiKey;
    if (this.currentApiKey === "" || null) {
      this.hasActiveApiKey = false;
    }
  }

  closeModal(): void {
    this.open = false;
  }

  async handleGenerateKey(): Promise<void> {
    this.submitting = true;
    const confirmed = await this.confirmDelete.open("Generate API key", "If you generate a new API key the current one will be deleted. Are you sure you want to generate a new API key?");

    if (confirmed) {
      User.generateApiKey().then(
        () => {
          this.currentApiKey = User.userApiKey;
          this.hasActiveApiKey = true;
          this.submitting = false;
          this.notification.message = "API key generated";
          this.notification.visible = true;
        },
        error => {
          this.submittedError = true;
          this.submitting = false;
        }
      );
    } else {
      this.submitting = false;
    }
  }

  async handleDeleteKey(): Promise<void> {
    this.submitting = true;
    const confirmed = await this.confirmDelete.open("Delete API key", "Are you sure you want to delete the current API key?");

    if (confirmed) {
      User.clearApiKey().then(
        () => {
          this.currentApiKey = User.userApiKey;
          this.hasActiveApiKey = false;
          this.submitting = false;
          this.notification.message = "API key deleted";
          this.notification.visible = true;
        },
        error => {
          this.showErrorSnackbar();
          this.submittedError = true;
          this.submitting = false;
        }
      );
    } else {
      this.submitting = false;
    }
  }

  showErrorSnackbar(): void {
    this.submittedError = true;
  }
}
