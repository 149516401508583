






















import store from "@/store";
import { Component, Vue, Prop } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UsersModule from "@/store/clients/Users.module";
import { UserProfile } from "@/types/userProfile";

const Users = getModule(UsersModule, store);

@Component
export default class OnlineUsersTable extends Vue {
  @Prop({ default: false }) isAdmin!: boolean;
  headers: { text: string; value: string; class: string; width?: string }[] = [
    {
      text: "Name",
      value: "name",
      class: "v-data-table-column-header",
      width: "100px",
    },
    {
      text: "Email",
      value: "email",
      class: "v-data-table-column-header",
      width: "120px",
    },
    {
      text: "Last activity",
      value: "lastActivityDate",
      class: "v-data-table-column-header",
      width: "180px",
    },
  ];
  adminHeaders = [
    {
      text: "Role",
      value: "role",
      class: "v-data-table-column-header",
      // maxWidth: "80px",
    },
    {
      text: "Company",
      value: "companyId",
      class: "v-data-table-column-header",
      // width: "200px",
    },
  ];

  get onlineUsers(): UserProfile[] {
    return Users.onlineUsers;
  }

  created(): void {
    if (this.isAdmin) this.headers = [...this.headers, ...this.adminHeaders];
  }
}
