




































































import { Component, Vue } from "vue-property-decorator";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import UsersModule from "@/store/clients/Users.module";
import UserModule from "@/store/clients/User.module";
import CompaniesModule from "@/store/clients/Companies.module";
import VesselsAdminModule from "@/store/clients/VesselsAdmin.module";
import VesselAccessGroupsModule from "@/store/clients/VesselAccessGroups.module";
import OnlineUsersTable from "@/components/profile/OnlineUsersTable.vue";
import InfoCardList from "@/components/profile/InfoCardList.vue";
import UserData from "@/components/profile/UserData.vue";
import { Route, Next } from "vue-router";

const User = getModule(UserModule, store);
const Users = getModule(UsersModule, store);
const Companies = getModule(CompaniesModule, store);
const VesselsAdmin = getModule(VesselsAdminModule, store);
const VesselAccessGroups = getModule(VesselAccessGroupsModule, store);

@Component({
  components: {
    UserData,
    InfoCardList,
    OnlineUsersTable,
  },
  beforeRouteEnter(to: Route, from: Route, next: Next): void {
    const usersPromise = Users.refreshUsers();
    const usersOnlinePromise = Users.refreshOnlineUsers();
    let promises = [usersPromise, usersOnlinePromise];

    //this endpoint is only accessible to admin/superuser serverside
    if (User.isSuperOrAdmin) {
      const accessGroupsPromise = VesselAccessGroups.refreshVesselAccessGroups();
      promises = [...promises, accessGroupsPromise];
    }
    //these endpoints are only accessible to admin serverside
    if (User.isAdmin) {
      const vesselsPromise = VesselsAdmin.refreshAllVessels();
      const companiesPromise = Companies.refreshCompanies();
      promises = [...promises, vesselsPromise, companiesPromise];
    }

    Promise.all(promises).then(() => {
      next();
    });
  },
})
export default class MyProfile extends Vue {
  daysBeforeVesselDataOutdated: number | null = null;
  vesselDataOutdatedUpdatedPopup = false;
  //User getters
  get isAdmin(): boolean {
    return User.isAdmin;
  }
  get isSuperOrAdmin(): boolean {
    return User.isSuperOrAdmin;
  }
  get daysBeforeDataOutdated(): number | null {
    return User.daysBeforeDataOutdated;
  }

  created(): void {
    this.daysBeforeVesselDataOutdated = this.daysBeforeDataOutdated;
  }

  async updateVesselDataOutdatedSetting(): Promise<void> {
    await User.updateDaysBeforeVesselDataOutdated(this.daysBeforeVesselDataOutdated).then(() => {
      this.vesselDataOutdatedUpdatedPopup = true;
    });
  }
}
